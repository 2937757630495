import React from "react"
import { Provider } from "react-redux"
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import store from "./src/state/createStore";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
// import createApolloClient from "./src/gatsby-plugin-apollo/client";



// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
	// const client = createApolloClient();
	// Instantiating store in `wrapRootElement` handler ensures:
	//  - there is fresh store for each SSR page
	//  - it will be called only once in browser, when React mounts
	let persister = persistStore(store);
	return(
		<>
		<GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY}>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persister}>
				{() => (
					element
				)}
			</PersistGate>
		</Provider>
		</GoogleReCaptchaProvider>
		</>
	)
}
